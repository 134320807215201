import React from 'react';
import PropTypes from 'prop-types';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class RecipientsField extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            recipients: props.recipients || []
        };


        this.onPaste = this.onPaste.bind(this);
    }

    onPaste(event) {
        const text = event.nativeEvent.clipboardData.getData('Text');
        const recipients = text.split('\n').map((row) => {
            const columns = row.split('\t');
            let email = '';
            let name = '';

            for (let column of columns) {
                if (column.includes('@')) {
                    email = email || column;
                } else {
                    name = name || column;
                }
            }

            return {
                email, name
            };
        }).filter(({ email }) => !!email);

        this.setState({ recipients });
    }

    removeEmail(email) {
        this.setState(({ recipients }) => {
            return {
                recipients: recipients.filter((recipient) => recipient.email && recipient.email !== email)
            };
        });
    }

    render() {
        const { recipients } = this.state;

        return (
            <div>
                {
                    this.props.editable &&
                    <input
                        type="hidden"
                        value={JSON.stringify(recipients)}
                        name="mailing_list_update[recipients]"
                        id="mailing_list_update_recipients"
                    />
                }

                <table>
                    <tbody>
                    <tr>
                        <th>
                            Email
                        </th>

                        <th>
                            Name
                        </th>

                        {
                            this.props.editable &&
                            <th/>
                        }
                    </tr>

                    {
                        recipients.map(({ email, name}, i) => {
                            return (
                                <tr key={i}>
                                    <td>{email}</td>
                                    <td>{name}</td>
                                    {
                                        this.props.editable &&
                                        <td>
                                            <FontAwesomeIcon icon={faTrash} onClick={() => this.removeEmail(email)} />
                                        </td>
                                    }
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>

                {
                    this.props.editable &&
                    <input value="Paste a spreadsheet here" onPaste={this.onPaste} onChange={() => {
                    }}/>
                }
            </div>
        );
    }
}

RecipientsField.propTypes = {
    recipients: PropTypes.arrayOf(PropTypes.object),
    editable: PropTypes.bool
};
