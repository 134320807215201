import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

const ReactRailsUJS = require('react_ujs'); // eslint-disable-line no-undef

Rails.start();
Turbolinks.start();
window.Turbolinks = Turbolinks;
ReactRailsUJS.detectEvents();
delete window.Turbolinks;
