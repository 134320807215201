import React from 'react';
import PropTypes from 'prop-types';

export default class CareerApplicationFilterForm extends React.PureComponent {

    static propTypes = {
        role: PropTypes.string.isRequired,
        roles: PropTypes.arrayOf(PropTypes.string).isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            role: props.role
        };
    }

    updateRole = (event) => {
        this.setState({role: event.target.value});
    }

    render() {
        const { role } = this.state;

        return (
            <div style={{ display: 'flex', columnGap: '6px' }}>
                <select value={role} onChange={this.updateRole}>
                    <option value="">All Roles</option>

                    {
                        this.props.roles.map((role) =>
                            <option key={role} value={role}>{role}</option>
                        )
                    }
                </select>

                <a href={`/career_applications?role=${role}`} className="btn btn-sm btn-primary">
                    Filter
                </a>

                {
                    role &&
                    <a href={`/career_applications/review?role=${role}`} className="btn btn-sm btn-primary">
                        Start Review
                    </a>
                }
            </div>
        );
    }

}
