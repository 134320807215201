import React from 'react';
import PropTypes from 'prop-types';

export default class CareerApplicationReview extends React.PureComponent {

    static propTypes = {
        offset: PropTypes.number.isRequired,
        role: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        careerApplication: PropTypes.shape({
            id: PropTypes.string,
            archived: PropTypes.bool,
            email: PropTypes.string,
            name: PropTypes.string,
            notes: PropTypes.string,
            resume_url: PropTypes.string,
            portfolio_url: PropTypes.string,
            reviewer_notes: PropTypes.string,
            role: PropTypes.string,
            submission_url: PropTypes.string,
            created_at: PropTypes.string,
            updated_at: PropTypes.string
        }).isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            reviewerNotes: (props.careerApplication || {}).reviewer_notes || ''
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyPress);
    }

    onKeyPress = (event) => {
        // if not in text area
        if (event.target.tagName !== 'TEXTAREA') {
            if (event.key === 'ArrowLeft') {
                this.updateOffset(this.props.offset - 1);
            } else if (event.key === 'ArrowRight') {
                this.updateOffset(this.props.offset + 1);
            } else if (event.key === 'Backspace' && event.shiftKey) {
                this.archive();
            }
        }

        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            this.setReviewerNotes().then(() => {
                this.updateOffset(this.props.offset + 1);
            });
        }
    }

    updateReviewerNotes = (event) => {
        this.setState({reviewerNotes: event.target.value});
    }

    updateOffset = (nextOffset) => {
        const params = new URLSearchParams(window.location.search);
        params.set('offset', Math.min(Math.max(nextOffset, 0), this.props.count));
        window.location.href = `/career_applications/review?${params.toString()}`;
    }

    updateCareerApplication = async (changes) => {
        const {careerApplication} = this.props;

        const response = await fetch(`/career_applications/${careerApplication.id}.json`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({
                career_application: changes
            })
        });

        if (!response.ok) {
            alert('Failed to save changes');
        }

        return response;
    }

    setReviewerNotes = () => {
        return this.updateCareerApplication({
            reviewer_notes: this.state.reviewerNotes
        });
    }

    archive = async () => {
        const response = await this.updateCareerApplication({
            archived: true
        });

        if (response.ok) {
            window.location.reload();
        }
    }

    render() {
        const {careerApplication} = this.props;
        const {reviewerNotes} = this.state;

        if (!careerApplication) {
            return (
                <div>
                    <h2>
                        All done!
                    </h2>

                    {
                        this.props.offset > 0 &&
                        <button className="btn btn-primary" onClick={() => this.updateOffset(this.props.offset - 1)}>
                            Previous
                        </button>
                    }
                </div>
            );
        }

        const subject = `WindBorne ${careerApplication.role} Position`;
        const emailBody = `Hi ${careerApplication.name.split(' ')[0]},\n\n`;

        // const mailTo = `mailto:${careerApplication.email}?`
        //  + `subject=${encodeURIComponent(subject)}`
        //     + `&body=${encodeURIComponent(emailBody)}`;

        const mailTo = `https://mail.google.com/mail/u/1/?fs=1&tf=cm&source=mailto&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}&to=${encodeURIComponent(careerApplication.email)}`;

        return (
            <div>
                <h2>
                    Application from {careerApplication.name}
                </h2>

                <p>
                    <strong>Email: </strong>
                    <a href={mailTo} target="_blank" rel="noopener noreferrer">
                        {careerApplication.email}
                    </a>
                </p>

                <p>
                    <strong>Role: </strong>
                    {careerApplication.role}
                </p>

                <p>
                    <strong>Submission url: </strong>
                    <a href={careerApplication.submission_url} target="_blank"
                       rel="noopener noreferrer">{careerApplication.submission_url}</a>
                </p>

                <p>
                    <strong>Resume url: </strong>
                    <a href={careerApplication.resume_url} target="_blank"
                       rel="noopener noreferrer">{careerApplication.resume_url}</a>
                </p>

                <p>
                    <strong>Portfolio url: </strong>
                    <a href={careerApplication.portfolio_url} target="_blank"
                       rel="noopener noreferrer">{careerApplication.portfolio_url}</a>
                </p>

                <p>
                    <strong>Notes: </strong>
                    {careerApplication.notes}
                </p>

                <br/>
                <br/>

                <hr/>

                <h3>
                    Reviewer notes

                    <span> </span>

                    <button className="btn btn-primary" onClick={this.setReviewerNotes}>
                        Save
                    </button>
                </h3>

                <br/>

                <textarea
                    className="form-control"
                    rows={8}
                    value={reviewerNotes}
                    onChange={this.updateReviewerNotes}
                />

                <br/>
                <br/>

                <button className="btn btn-primary" onClick={this.archive}>
                    Archive
                </button>

                <br/>
                <br/>

                <div>
                    <button className="btn btn-primary" onClick={() => this.updateOffset(this.props.offset - 1)}>
                        Previous
                    </button>

                    <span> {this.props.offset + 1} / {this.props.count} </span>

                    <button className="btn btn-primary" onClick={() => this.updateOffset(this.props.offset + 1)}>
                        Next
                    </button>
                </div>

                <hr/>

                <div>
                    <a href={`/career_applications/${careerApplication.id}/edit`}>Edit this career application</a> |
                    <a href="/career_applications">Back to career applications</a>

                    <br/>
                    <br/>

                    <form action={`/career_applications/${careerApplication.id}`} method="post">
                        <input type="hidden" name="_method" value="delete"/>
                        <input type="submit" value="Destroy this career application"/>
                    </form>
                </div>
            </div>
        );
    }

}
