import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../images/logo.svg';
import Immutable from 'immutable';

export default class InternalTopbar extends React.PureComponent {

    render() {
        const user = Immutable.Map(this.props.user);
        const username = user.get('name') || user.get('email');
        const loggedIn = !!user.get('id');

        return (
            <header className="topbar">
                <a href={this.props.homeLink} data-turbolinks={this.props.homeLink.startsWith('/admin/')}>
                    <div className="brand-logo">
                        <img src={Logo} alt="WindBorne Systems Logo" />
                        <span>WindBorne Systems</span>
                    </div>
                </a>

                <div className="title">
                    {
                        this.props.pageName
                    }
                </div>

                <div className="right-section">
                    <span>
                        {
                            loggedIn ?
                                <a href="/users/profile" data-turbolinks={false}>
                                    Logged in as {username}
                                </a> :
                                <a>
                                    <a href="/users/sign_in" data-turbolinks={false}>
                                        Log in
                                    </a>
                                </a>
                        }
                    </span>
                </div>
            </header>
        );
    }

}

InternalTopbar.defaultProps = {
    homeLink: '/dashboard'
};

InternalTopbar.propTypes = {
    user: PropTypes.oneOfType([PropTypes.instanceOf(Immutable.Map), PropTypes.object]).isRequired,
    pageName: PropTypes.string,
    homeLink: PropTypes.string
};
