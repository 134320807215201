/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '../stylesheets/internal.scss';
import '../init_pack';
import 'regenerator-runtime/runtime';

const componentRequireContext = require.context('components/internal', true); // eslint-disable-line no-undef
const ReactRailsUJS = require('react_ujs'); // eslint-disable-line no-undef
ReactRailsUJS.useContext(componentRequireContext);
